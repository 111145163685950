import React, {useCallback, useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {CircularProgress, Divider, Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import ContactTable from "../components/ContactTable";
import Contact, {emptyContact} from "../store/models/Contact";
import {buildUserFeedbackStringFromSearchResult} from "../util/SearchUtil";
import {
    thunkDeleteContactList,
    thunkGetContactLists,
    thunkSaveNewContactList,
    thunkSelectContactList
} from "../store/actions/ContactListActions";
import ContactList from "../store/models/ContactList";
import {Pagination} from "@material-ui/lab";
import {searchPaginationPageSize} from "../store/Api";
import {useTranslation} from "react-i18next";
import ContactEditModal from "../components/contactDetail/ContactEditModal";
import ContactListChip from "../components/ContactListChip";
import {useParams} from "react-router-dom";


function ContactListsView() {
    const {preselectedList} = useParams<{ preselectedList: string }>();
    const dispatch = useDispatch();
    let contactLists: ContactList[] = useSelector((state: IStore) => {
        return state.contactLists.contactLists
    });
    const [selectedContacts, setSelectedContacts] = useState<string[]>([])
    useEffect(() => {
        dispatch(thunkGetContactLists())
    }, [dispatch]);
    const {t} = useTranslation();
    let searchResult: Contact[] = useSelector((state: IStore) => state.contactLists.currentContactsListResult)
    const page = useSelector((state: IStore) => state.contactLists.page);
    const selectedList = useSelector((state: IStore) => state.contactLists.selectedList);
    const totalResults = useSelector((state: IStore) => state.contactLists.totalResults);
    const withDistanceKm = useSelector((state: IStore) => state.contactLists.withDistanceKm);

    const [isLoading, setIsLoading] = useState(false);

    const selectContactList = useCallback(async (cL: ContactList) => {
        setIsLoading(true);
        await dispatch(thunkSelectContactList(cL, 1))
        setIsLoading(false);
    },[setIsLoading,dispatch])

    const deleteFromContactList = async (stContactID: string) => {
        if (selectedList && selectedList.selectedContactIds) {
            const newContactList: ContactList = {
                ...selectedList,
                selectedContactIds: selectedList.selectedContactIds.filter(value => value !== stContactID)
            }
            await dispatch(thunkSaveNewContactList(newContactList));
            await dispatch(thunkSelectContactList(newContactList, page));
        }
    }

    const switchPage = async (pageNumber: number) => {
        setIsLoading(true);
        await dispatch(thunkSelectContactList(selectedList!, pageNumber))
        setIsLoading(false);
    }

    useEffect(() => {
        if (preselectedList) {
            const selectedList = contactLists.find(cL => cL.customerProcessingQuery === preselectedList)
            if (selectedList) {
                selectContactList(selectedList)
            }
        }
    }, [preselectedList, contactLists, dispatch,selectContactList]);

    return (<>
        <Navigation breadcrumbName={t('contactListsView.breadcrumb')}>

            <Grid container spacing={3}>
                <Grid item md={12} container>
                    <Grid item>
                        <Typography variant={"h6"}>{t('contactListsView.systemContactListHeadline')}</Typography>
                        {contactLists.filter(cL => !cL.contactListID).map(cL =>
                            <ContactListChip
                                key={'systemCL' + cL.name}
                                label={cL.name}
                                onClick={() => selectContactList(cL)}
                                variant={(selectedList && selectedList.name === cL.name) ? 'outlined' : 'default'}
                            />
                        )}
                    </Grid>
                    <div style={{flexGrow: 1, display: 'flex'}}></div>
                    <Grid item>
                        <ContactEditModal contact={emptyContact} isEdit={false}/>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Divider/>
                </Grid>
                <Grid item md={12}>
                    <Typography variant={"h6"}>{t('contactListsView.customContactListHeadline')}</Typography>
                    {contactLists.filter(cL => !!cL.contactListID).map(cL =>
                        <ContactListChip
                            key={'customCL' + cL.name}
                            label={cL.name}
                            onDelete={() => dispatch(thunkDeleteContactList(cL.contactListID!))}
                            onClick={() => selectContactList(cL)}
                            variant={(selectedList && selectedList.name === cL.name) ? 'outlined' : 'default'}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={3} direction={"column"}>
                {selectedList || isLoading ? <>
                        <Grid item>
                            <Typography> {buildUserFeedbackStringFromSearchResult(totalResults, '', selectedList?.query, true, t)}</Typography>
                            {isLoading ? <CircularProgress/> :
                                <ContactTable selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts}
                                              selectedContactList={selectedList} contacts={[...searchResult]}
                                              withDistanceKm={withDistanceKm}
                                              deleteFromContactList={deleteFromContactList}/>
                            }
                        </Grid>
                        <Grid item>
                            <Pagination count={Math.floor(totalResults / searchPaginationPageSize) + 1} page={page}
                                        onChange={(e, page) => switchPage(page)}/>
                        </Grid>
                    </>
                    : <><br/><br/><br/><Typography align={"center"} variant="h5" component="h5">
                        {t('contactListsView.noListSelected')}</Typography></>}
            </Grid>
        </Navigation>
    </>)
}

export default ContactListsView;
