import React, {FunctionComponent, useEffect, useState} from 'react'
import {Grid, InputLabel, MenuItem, Select} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import {fetchUserDisplayname} from "../../util/UserUtil";

type TCustomTextData = {
    name?: string
    template?: string
}

type TAnchor = HTMLElement | null

interface ICustomTextPopoverProps {
    anchor: TAnchor;
    onSubmit: (data: TCustomTextData, insert: boolean) => void
}

type TCustomTextPopoverState = {
    anchor: TAnchor
    isCancelled: boolean
}

const cardPopverStyles = makeStyles({
    root: {
        padding: 10,
        maxWidth: 350
    },
    textField: {
        width: "100%"
    }
})


const tempalteOptions = [
    {
        label: 'Termin',
        value: 'coldCall'
    }
    // , {
    //     label: 'Nachfass Termin',
    //     value: 'followUp'
    // }, {
    //     label: 'Digitalangebot',
    //     value: 'digitalOffer'
    // },
]


function fetchTemplateByTemplateKey(templateKey: string, dateString: string, timeString: string, phoneNumber: string, emailAddress: string) {
    switch (templateKey) {
        case 'coldCall':
            return <>
                <p>vielen Dank für Ihr Interesse an unseren Produkten. In unserem persönlichen Gespräch präsentiere ich
                    Ihnen gerne eine Auswahl individuell auf Sie zugeschnittener Werbelösungen. Unser Angebot umfasst:
                    Werbeartikel, Geschenke, Unternehmenskleidung, Geschäftsausstattung (Visitenkarten, Briefpapier,
                    etc.) und digitale Produkte (Webseiten). Alles aus einer Hand - alles für Ihren professionellen
                    Auftritt. Ich freue mich auf unser Gespräch.<br/>
                    <br/>
                    Unser Termin findet am {dateString} um {timeString} Uhr statt.<br/>
                    Sollten Sie am vereinbarten Termin verhindert sein oder noch Fragen, Wünsche oder Mitteilungen
                    haben,
                    informieren Sie mich bitte unter folgender Telefonnummer:{phoneNumber}<br/>
                    <br/>
                    Ich wünsche Ihnen noch einen erfolgreichen Tag.<br/>
                    <br/>
                    Mit freundlichen Grüßen<br/>
                    {fetchUserDisplayname()}<br/>
                    <br/>
                    Berendsohn AG<br/>
                    Friesenweg 22<br/>
                    22763 Hamburg<br/>
                    T {phoneNumber}<br/>
                    <a href={`mailto:${emailAddress}`}>{emailAddress}</a><br/>
                    <a href={'https://www.berendsohn.com'}>www.berendsohn.com</a>
                </p>
            </>
        case 'followUp':
            return <>
                <p>hatten Sie schon Zeit, sich Gedanken über mein Angebot zu machen?</p>
            </>
        case 'digitalOffer':
            return <>
                <p>ich würde Ihnen gerne unsere neuen Digitalen Angebote vorstellen.</p>
            </>
        default:
            return ''
    }
}


export const CustomText: FunctionComponent<any> = (props) => {
    const {blockProps, admData, timeString, dateString} = props

    const phoneNumber = admData?.contactDetails.mobileNumber || admData?.contactDetails.telephoneNumber
    const emailAddress = admData?.contactDetails.emailAddress;


    return (<>
            <p>{blockProps.name}</p>
            {fetchTemplateByTemplateKey(blockProps.template, dateString, timeString, phoneNumber, emailAddress)}
        </>
    )
}

export const CustomTextPopover: FunctionComponent<ICustomTextPopoverProps> = (props) => {
    const classes = cardPopverStyles(props)
    const [state, setState] = useState<TCustomTextPopoverState>({
        anchor: null,
        isCancelled: false
    })
    const [data, setData] = useState<TCustomTextData>({template: 'coldCall', name: 'Sehr geehrte Damen und Herren,'})

    useEffect(() => {
        setState({
            anchor: props.anchor,
            isCancelled: false
        })
    }, [props.anchor])

    const handleChange = (event: React.ChangeEvent<any>) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }
    const textFieldProps = {
        onChange: handleChange,
    }

    return (
        <Popover
            anchorEl={state.anchor}
            open={state.anchor !== null}
            onExited={() => {
                props.onSubmit(data, !state.isCancelled)
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        label="Anrede und Name"
                        name="name"
                        fullWidth
                        value={data.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Vorlage</InputLabel>
                    <Select
                        {...textFieldProps}
                        name="template"
                        value={data.template}
                        fullWidth
                        label={'Template'}
                    >
                        {tempalteOptions.map(tO => <MenuItem
                            key={'template' + tO.label}
                            value={tO.value}>{tO.label}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: true
                        })
                    }}
                    >
                        <CloseIcon/>
                    </Button>
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: false
                        })
                    }}
                    >
                        <DoneIcon/>
                    </Button>
                </Grid>
            </Grid>
        </Popover>
    )
}
